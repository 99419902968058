const datalayerPushEvent = require('../../lib/datalayerPushEvent')

const vDatalayer = {
  bind (el, binding, vnode) {
    // const { value } = binding
    el.addEventListener('click', datalayerPushEvent)
  },
  unbind (el, binding, vnode) {
    // const { value } = binding
    el.removeEventListener('click', datalayerPushEvent)
  }
}

module.exports = vDatalayer
