import { TweenMax, TimelineMax, Power4, Linear } from 'gsap'
import _ from 'lodash'
import { throttle } from 'lodash/fp'
import { mq } from '../helpers/detect-screensize'
import isTouchscreen from '../helpers/detect-touchscreen'

const init = () => {
  if (window.siteLogoInitialized) return
  window.siteLogoInitialized = true

  const logoElm = document.querySelector('.site-logo')
  if (!logoElm) return

  if (mq.matches && isTouchscreen) {
    return
  }

  const siteLogoLodash = document.querySelectorAll('.site-logo__primary__logo rect, .site-logo__secondary__logo rect')
  const logoElmSvg = document.querySelector('.site-logo__secondary__logo')
  const logoRevealElm = document.querySelector('.site-logo__secondary__reveal')
  const logoElmHeight = 160
  const logoClass = 'site-logo--active'
  const tlMaster = new TimelineMax({ paused: true })
  const tlBlink = new TimelineMax({ repeat: -1, repeatDelay: 10 })
  let animPlayed = false

  function getBlinkLodash () {
    const tl = new TimelineMax({ id: 'getBlinkLodash' })

    tl.fromTo(siteLogoLodash, 0.3, { autoAlpha: 1 }, { autoAlpha: 0, repeat: 5, yoyo: true, ease: Linear.easeNone })

    return tl
  }

  tlBlink.add(getBlinkLodash())

  /**
   * [getSideRevealAnim description]
   * @method getSideRevealAnim
   * @return {[type]}          [description]
   */

  function getSideRevealAnim () {
    const tl = new TimelineMax({ id: 'getSideRevealAnim' })

    tl
      .set(logoRevealElm, { autoAlpha: 1, x: '-100%' })
      .fromTo(logoRevealElm, 0.4, { x: '-100%' }, { x: '0%', ease: Power4.easeOut })
      .set(logoElmSvg, { autoAlpha: 1 })
      .to(logoRevealElm, 0.2, { x: '100%', ease: Power4.easeOut })
      .set(logoRevealElm, { autoAlpha: 0 })

    return tl
  }

  /**
   * [getSideHideAnim description]
   * @method getSideHideAnim
   * @return {[type]}        [description]
   */

  function getSideHideAnim () {
    const tl = new TimelineMax({ id: 'getSideHideAnim' })

    tl.to(logoElmSvg, 0.2, { autoAlpha: 0, ease: Linear.easeNone })

    return tl
  }

  /**
   * When scrolled down reveal secondary logo
   * @type {[type]}
   */

  function elementScroller () {
    let elmScrollPos = window.scrollY > logoElmHeight
    if (elmScrollPos === true && animPlayed === false) {
      animPlayed = true
      logoElm.classList.add(logoClass)
      tlMaster.play().add(getSideRevealAnim())
    } else if (elmScrollPos === false && animPlayed === true) {
      animPlayed = false
      logoElm.classList.remove(logoClass)
      tlMaster.play().add(getSideHideAnim())
    }
  }

  window.addEventListener('scroll', _.throttle(elementScroller, 100))
}

document.addEventListener('DOMContentLoaded', init, { once: true })
