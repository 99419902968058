import { TweenMax, Linear } from 'gsap'

const init = () => {
  const tickers = Array.from(document.querySelectorAll('.ticker'))
  if (!tickers.length > 0) return

  const options = {
    root: null,
    rootMargin: '0px 100%',
    threshold: 0
  }

  const handleIntersect = entries => {
    entries.forEach(entry => {
      const ratio = entry.intersectionRatio

      if (ratio > 0 && !entry.target.classList.contains('active')) {
        TweenMax.fromTo(entry.target, 15, { className: '+=active', x: '0%' }, { x: '-100%', repeat: -1, ease: Linear.easeNone })
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersect, options)

  tickers.forEach(ticker => {
    if (!ticker.hasAttribute('ticker--created')) {
      ticker.setAttribute('ticker--created', '')
      observer.observe(ticker)
    }
  })
}

document.addEventListener('DOMContentLoaded', init, { once: true })

export { init }
