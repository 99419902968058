const datalayerPush = require('./datalayerPush')

const isValidJson = data => {
  try {
    JSON.parse(data)
    return true
  } catch (e) {
    return false
  }
}

const findElementHasAttribute = (el, eventElement, attributeName) => {
  if (el === eventElement) {
    if (el.nodeType === 1 && el.hasAttribute(attributeName)) return el
    return null
  }
  while (el.parentNode) {
    el = el.parentNode
    console.log(el.nodeType === 1 && el.hasAttribute(attributeName))
    if (el.nodeType === 1 && el.hasAttribute(attributeName)) return el
  }
  return null
}

const datalayerPushEvent = event => {
  let value
  let parsedJson
  let attributeName = 'data-datalayer'
  let el = findElementHasAttribute(event.target, event.currentTarget, attributeName)

  event.stopPropagation()
  if (el) {
    value = el.getAttribute(attributeName)
    if (isValidJson(value)) {
      parsedJson = JSON.parse(value)
      datalayerPush(parsedJson)
    }
  }
}

module.exports = datalayerPushEvent
