const remapItemsForGrid = (n, items, highlightedN) => {
  const list = []
  let
    columnContainer = [],
    accumulatorCol = 0,
    rowN = 0,
    msGridColumnOffset = 0

  items.forEach((item, index) => {
    const colN = index === highlightedN ? 12 : n || 12
    const msGridColumn = msGridColumnOffset + 1

    if (!accumulatorCol) {
      list.push({
        props: {
          rowN,
          'fr-row': 1,
          'fr-col': 12,
          '-ms-grid-rows': '1fr 10px',
          '-ms-grid-row': rowN + rowN * 1 + 1,
          '-ms-grid-column': 1,
          '-ms-grid-column-span': 23
        },
        columns: columnContainer
      })
      ++rowN
    }
    accumulatorCol += colN
    if (accumulatorCol && accumulatorCol <= 12) {
      columnContainer.push({
        props: {
          colN,
          'fr-row': 1,
          'fr-col': colN,
          '-ms-grid-row': 1,
          '-ms-grid-column': msGridColumn,
          'isHighlighted': index === highlightedN
        },
        item
      })
    } else {
      columnContainer = []
      columnContainer.push({
        props: {
          colN,
          'fr-row': 1,
          'fr-col': colN,
          '-ms-grid-row': 1,
          '-ms-grid-column': 1,
          'isHighlighted': index === highlightedN
        },
        item
      })
      list.push({
        props: {
          rowN,
          'fr-row': 1,
          'fr-col': 12,
          '-ms-grid-rows': '1fr 10px',
          '-ms-grid-row': rowN + rowN * 1 + 1,
          '-ms-grid-column': 1,
          '-ms-grid-column-span': 23
        },
        columns: columnContainer
      })
      // reset and add values to accumulators
      ++rowN
      accumulatorCol = colN
      msGridColumnOffset = 0
    }
    msGridColumnOffset += colN * 2
  })
  return list
}

module.exports = remapItemsForGrid
