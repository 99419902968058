const formSerialize = require('form-serialize')
const axiosBluebird = require('../vue/lib/axiosBluebird')
const urlQueryToParams = require('../vue/lib/urlQueryToParams')

let dataRequest = Promise.resolve()
const attachedAttribute = 'form-whitepaper--attached'

const formSubmitEvent = event => {
  event.preventDefault()
  const form = event.target
  let url = form.action
  let params = formSerialize(form, { hash: true })

  if (url.includes('?')) {
    params = {...urlQueryToParams(url), ...params}
    url = url.slice(0, url.indexOf('?'))
  }

  if (form.method === 'get') {
    dataRequest = axiosBluebird.get(url, params)
  } else {
    dataRequest = axiosBluebird.post(url, params)
  }

  dataRequest
  .then(json => {
    const { url } = json.data
    if (url) window.location = url
    console.log('success', { url, params })
  })
  .catch(console.error.bind(console, 'FAIL - formSubmitEvent:'))
  
  console.log('formSubmit')
}

const attachElement = el => {
  console.log('ok')
  if (!el.hasAttribute(attachedAttribute)) {
    el.addEventListener('submit', formSubmitEvent)
    el.setAttribute(attachedAttribute, '')
  }
}

const detachElement = el => {
  if (el.hasAttribute(attachedAttribute)) {
    el.removeEventListener('submit', formSubmitEvent)
    el.removeAttribute(attachedAttribute)
  }
}

const init = () => {
  const forms = document.querySelectorAll('.form-whitepaper-js')
  Array.from(forms).forEach(attachElement)
}

const destroy = () => {
  const forms = document.querySelectorAll('.form-whitepaper-js')
  Array.from(forms).forEach(detachElement)
}

document.addEventListener('DOMContentLoaded', init)

export { init, attachElement, detachElement, destroy }
