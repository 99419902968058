const findParentElement = (el, target) => {
  while (el.parentNode) {
    el = el.parentNode
    if (el === target) return el
  }
  return null
}
const vClickOutside = {
  bind (el, binding, vnode) {
    el.event = event => {
      if (!findParentElement(event.target, el)) binding.value()
    }
    document.body.addEventListener('click', el.event)
  },
  unbind (el, binding, vnode) {
    document.body.removeEventListener('click', el.event)
  }
}

module.exports = vClickOutside
