import { TweenMax, TimelineMax, Power1 } from 'gsap'
import _ from 'lodash'
import { throttle } from 'lodash/fp'
import { inview } from '../helpers/detect-is-in-viewport'

const init = () => {
  const loop = document.querySelector('.v-text-block__loop')
  if (!loop) return
  const loopTitles = document.querySelectorAll('.v-text-block__loop__subtitle')
  const nrCircles = document.querySelectorAll('.nr-circle')
  const tlMaster = new TimelineMax({ paused: true })
  let animPlayed = false

  function getLoop () {
    const tl = new TimelineMax({ id: 'getIconNum' })

    if (animPlayed === false) {
      tl
        .staggerFromTo(nrCircles, 0.8, { scale: 0.9 }, { scale: 1, autoAlpha: 1, ease: Power1.easeOut }, 0.4, 'start')
        .staggerFromTo(loopTitles, 0.8, { x: -30 }, { x: 0, autoAlpha: 1, ease: Power1.easeOut }, 0.4, 'start')

      animPlayed = true
    }

    return tl
  }

  function loopInview () {
    if (inview(loop)) {
      tlMaster.play().add(getLoop())
    }
  }

  window.addEventListener('scroll', _.throttle(loopInview, 200))
  window.addEventListener('load', loopInview())
}

document.addEventListener('DOMContentLoaded', init)
