import { TweenMax, TimelineMax, Linear } from 'gsap'
import ThrowPropsPlugin from '../gsap-bonus/ThrowPropsPlugin'

const init = () => {
  const iconBlock = Array.from(document.querySelectorAll('.icon-block'))
  if (!iconBlock.length > 0) return

  const icons = document.querySelectorAll('.icon-block__icon')
  const iconTitle = document.querySelectorAll('.icon-block__hgroup__title')

  const IconAnim = function () {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    }

    function handleIntersect (entries, observer) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0 && !entry.target.classList.contains('played')) {
          entry.target.classList.add('played')

          TweenMax.staggerFromTo(icons, 0.8, { scale: 0.96 }, { scale: 1, autoAlpha: 1, ease: Linear.easeNone }, 0.2)

          iconTitle.forEach((item, i) => {
            let num = { total: 0 }
            const numTotal = item.dataset.num

            item.classList.add('active')

            TweenMax.to(num, 4, { total: numTotal, roundProps: 'total', onUpdate: updateHandler, ease: Linear.easeNone, onComplete: () => { item.classList.remove('active') } })

            function updateHandler () {
              item.innerHTML = num.total
            }
          })
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, options)
    iconBlock.forEach(item => observer.observe(item))
  }

  IconAnim()
}

document.addEventListener('DOMContentLoaded', init, { once: true })
