'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'Modal',
  props: {
    props: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    onClose: function onClose(event) {
      this.$emit('close');
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"modal micromodal-slide\" id=\"modal-1\" aria-hidden=\"true\">\n  <div class=\"modal__overlay\" tabindex=\"-1\" data-micromodal-close=\"\">\n    <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"modal-1-title\" v-click-outside=\"onClose\">\n      <header class=\"modal__header\">\n        <h3 class=\"modal__header__title\" v-if=\"props.modal.title\" v-text=\"props.modal.title\">Header Title</h3>\n        <button class=\"modal__close\" aria-label=\"Close modal\" data-micromodal-close=\"\" @click=\"onClose\">\n          <svg-use :href=\"$root.distFolder + '/img/img.svg#icon-close'\"></svg-use>\n        </button>\n      </header>\n      <main class=\"modal__content\" id=\"modal-1-content\">\n        <h4 class=\"modal__content__title\" v-if=\"props.modal.contentTitle\" v-text=\"props.modal.contentTitle\">Modal Content Title</h4>\n      \t<slot></slot>\n        <component :is=\"props.modal.component\" :item=\"props.item\"></component>\n      </main>\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-19d50c5c", module.exports)
  } else {
    hotAPI.update("_v-19d50c5c", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}