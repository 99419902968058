const init = () => {
  const inputs = Array.from(document.querySelectorAll('.file-input'))
  inputs.forEach(input => {
    if (!input.hasAttribute('input--created')) {
      input.setAttribute('input--created', '')

      const label = input.nextElementSibling
      const labelVal = label.innerHTML

      input.addEventListener('change', function (e) {
        let fileName = ''
        if (this.files && this.files.length > 1) {
          fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length)
        } else {
          fileName = e.target.value.split('\\').pop()
        }

        if (fileName) {
          label.querySelector('span').innerHTML = fileName
        } else {
          label.innerHTML = labelVal
        }
      })
    }
  })
}

document.addEventListener('DOMContentLoaded', init)

export { init }
