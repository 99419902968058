const { attachElement, detachElement } = require('../../../partials/reveal-box')
const vAnimationRevealBox = {
  bind (el, binding, vnode) {
    attachElement(el)
  },
  unbind (el, binding, vnode) {
    detachElement(el)
  }
}

module.exports = vAnimationRevealBox
