const datalayerPushEvent = require('../../lib/datalayerPushEvent')

const vDatalayerDelegation = {
  bind (el, binding, vnode) {
    el.addEventListener('click', datalayerPushEvent)
  },
  unbind (el, binding, vnode) {
    el.removeEventListener('click', datalayerPushEvent)
  }
}

module.exports = vDatalayerDelegation
