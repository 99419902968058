const mq = window.matchMedia('(max-width: 939px)')
const html = document.documentElement
const screenClass = 'sm-screen'

const mqResponse = (mq) => {
  if (mq.matches) {
    html.classList.add(screenClass)
  } else {
    html.classList.remove(screenClass)
  }
}

mqResponse(mq)
mq.addListener(mqResponse)

// Exports
exports.mq = mq
