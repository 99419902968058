import { TweenMax, TimelineMax, Power3 } from 'gsap'

const init = () => {
  const slideZoomElm = Array.from(document.querySelectorAll('.slide-zoom-in'))
  if (!slideZoomElm.length > 0) return

  const slideFadeElmAnim = function () {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4
    }

    function handleIntersect (entries, observer) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0 && !entry.target.classList.contains('played')) {
          TweenMax.fromTo(entry.target, 1.4, { scale: 1 }, { scale: 1.1, 'filter': 'brightness(0.5)', ease: Power3.easeOut, onStart: () => { entry.target.classList.add('played') } })
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, options)
    slideZoomElm.forEach(item => observer.observe(item))
  }

  slideFadeElmAnim()
}

document.addEventListener('DOMContentLoaded', init, { once: true })
