import Vue from './init/Vue'

const VueApp = new Vue(require('./components/index.vue'))
// VueApp.$mount('vue')
const MountVue = () => {
  Array.from(document.querySelectorAll('vue:not([vue-init="mounted"])')).forEach(element => {
    element.setAttribute('vue-init', 'mounted')
    VueApp.$mount(element)
  })
}

const DismountVue = () => {
  Array.from(document.querySelectorAll('vue[vue-init="mounted"]')).forEach(element => {
    VueApp.$destroy(element)
    element.removeAttribute('vue-init')
  })
}

const RemountVue = () => {
  DismountVue()
  MountVue()
}

window.MountVue = MountVue
window.DismountVue = DismountVue
window.RemountVue = RemountVue

document.addEventListener('DOMContentLoaded', RemountVue, { once: true })
