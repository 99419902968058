import { TweenMax, TimelineMax, Power4, Linear } from 'gsap'
import DrawSVGPlugin from '../gsap-bonus/DrawSVGPlugin'

const init = () => {
  const thumbsBtn = Array.from(document.querySelectorAll('.profile__thumbs__item'))
  if (!thumbsBtn.length > 0) return

  const thumbsList = document.querySelector('.profile__thumbs__list')
  const thumbs = Array.from(document.querySelectorAll('.profile__thumbs__item'))
  const profileSlider = document.querySelector('.profile__slider')
  const slides = Array.from(document.querySelectorAll('.profile__slide'))

  TweenMax.set('.profile__thumbs__item__polyline', { drawSVG: '0%' })

  const initActiveSlide = function () {
    const firstThumbPoly = document.querySelector('.profile__thumbs__item__polyline')
    const firstSlide = document.querySelector('.profile__slide')

    TweenMax.set(firstThumbPoly, { drawSVG: '100%' })
    TweenMax.set(firstSlide, { autoAlpha: 1 })
  }

  const switchSlide = function (event, state) {
    if (event.target.tagName.toLowerCase() === 'button') {
      let i = Array.from(thumbsList.children).indexOf(event.target.parentElement)

      player.setIndex(i)
    }
  }

  const thumbsContainer = (thumbs, i) => {
    thumbs.forEach((thumb, index) => {
      const tl = new TimelineMax()

      if (i === index) {
        tl
          .fromTo(thumb.querySelector('polyline'), 1.0, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power4.easeInOut })
      } else {
        tl
          .to(thumb.querySelector('polyline'), 0.8, { drawSVG: '100% 100%', ease: Power4.easeInOut })
      }
    })
  }

  const slidesContainer = (slides, i) => {
    slides.forEach((slide, index) => {
      const tl = new TimelineMax()
      const slideText = slide.querySelector('.profile__slide__text')
      if (i === index) {
        tl
          .fromTo(slide, 0.8, { autoAlpha: 0, scale: 0.8 }, { autoAlpha: 1, scale: 1.0, ease: Power4.easeOut })
          .staggerFromTo(slideText.children, 0.5, { autoAlpha: 0, y: -20 }, { autoAlpha: 1, y: 0, ease: Linear.none }, 0.1, '-=0.6')
      } else {
        tl
          .to(slide, 0.4, { autoAlpha: 0, ease: Linear.none })
      }
    })
  }

  const player = {
    loop: true,
    currentIndex: 0,
    totalItems: thumbsList.children.length,
    setIndex (index) {
      slidesContainer(slides, index)
      thumbsContainer(thumbs, index)
      this.currentIndex = index
    },
    next () {
      let index = this.currentIndex
      let length = this.totalItems - 1
      let loop = this.loop

      ++index

      if (index > length) {
        index = loop ? 0 : length
      }

      this.currentIndex = index
      this.setIndex(this.currentIndex)
    }
  }

  const stopWatch = {
    intervalIndex: null,
    start () {
      this.intervalIndex = setInterval(() => {
        player.next()
      }, 3000)
    },
    pause () {
      clearInterval(this.intervalIndex)
    }
  }

  const addEventListenerMulti = (element, events, fn) => {
    events.forEach((event) => element.addEventListener(event, fn))
  }

  const addEventListeners = (thumbsBtn) => {
    addEventListenerMulti(thumbsBtn, ['click', 'touchstart'], (event) => {
      switchSlide(event, 'active-slide')
    })
  }

  const addEventListenersSlider = (profileSlider) => {
    addEventListenerMulti(profileSlider, ['mouseover', 'focus'], (event) => {
      stopWatch.pause()
    })
    addEventListenerMulti(profileSlider, ['mouseleave', 'blur'], (event) => {
      stopWatch.start()
    })
  }

  const attachEventListeners = (thumbsBtn) => {
    addEventListeners(thumbsBtn)
  }

  thumbsBtn.forEach(attachEventListeners)
  initActiveSlide()
  stopWatch.start()
  addEventListenersSlider(profileSlider)
}

document.addEventListener('DOMContentLoaded', init)

export { init }
