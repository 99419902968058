import Particles from 'particlesjs'

const init = () => {
  const canvas = document.querySelector('.valtech-bg')
  if (!canvas) return

  Particles.init({
    selector: '.valtech-bg',
    color: '#111',
    sizeVariations: 3,
    maxParticles: 140,
    responsive: [
      {
        breakpoint: 768,
        options: {
          maxParticles: 70
        }
      }, {
        breakpoint: 320,
        options: {
          maxParticles: 35
        }
      }
    ]
  })
}

document.addEventListener('DOMContentLoaded', init)

export { init }
