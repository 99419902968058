'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: 'SocialSharing',
    props: ['socialChannels', 'subject', 'summary', 'hashTags', 'svgDirectory'],
    computed: {
        hashtag: function hashtag() {
            return this.hashTags ? '&hashtags=' + this.hashTags : '';
        },
        socials: function socials() {
            return {
                email: 'mailto:?subject=' + this.subject + '&body=' + this.summary + '%0D%0A' + window.location.href,
                twitter: 'https://twitter.com/intent/tweet?text=' + document.title + '&url=' + window.location.href + this.hashtag,
                facebook: 'https://facebook.com/sharer.php?u=' + window.location.href,
                linkedin: 'https://www.linkedin.com/shareArticle?url=' + window.location.href + '&title=' + document.title + '&summary=' + this.summary
            };
        }
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"social-sharing\">\n    <slot></slot>\n    <a v-for=\"(item,index) in socialChannels\" :key=\"index\" target=\"_blank\" :href=\"socials[item]\" :class=\"'social-sharing__' + item\">\n        <svg class=\"social-sharing__icon\">\n            <use :xlink:href=\"svgDirectory + item\"></use>\n        </svg>\n    </a>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-5eea1f5d", module.exports)
  } else {
    hotAPI.update("_v-5eea1f5d", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}