import { TweenMax, TimelineMax, Expo } from 'gsap'

const buttonAnim = function (event, state) {
  let currentItem = event.currentTarget
  const beforeElm = currentItem.querySelector('.before')
  const afterElm = currentItem.querySelector('.after')

  const tl = new TimelineMax()

  if (state === 'in') {
    tl
      .fromTo(beforeElm, 0.8, { clipPath: 'inset(0% 100% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', ease: Expo.easeOut }, 'start')
      .fromTo(afterElm, 0.8, { x: '-100%' }, { x: '0%', ease: Expo.easeOut }, 'start')
  } else {
    tl
      .to(beforeElm, 0.5, { clipPath: 'inset(0% 0% 0% 100%)', ease: Expo.easeInOut }, 'start')
      .fromTo(afterElm, 0.5, { x: '0%' }, { x: '100%', ease: Expo.easeInOut }, 'start')
  }
}

const addEventListenerMulti = (element, events, fn) => {
  events.forEach((event) => element.addEventListener(event, fn))
}

const removeEventListenerMulti = (element, events, fn) => {
  events.forEach((event) => element.removeEventListener(event, fn))
}

const addEventListeners = (button) => {
  if (!button.hasAttribute('button-animation--events-added')) {
    addEventListenerMulti(button, ['mouseenter', 'focus'], (event) => {
      buttonAnim(event, 'in')
    })

    addEventListenerMulti(button, ['mouseleave', 'blur'], (event) => {
      buttonAnim(event, 'out')
    })

    button.setAttribute('button-animation--events-added', '')
  }
}

const removeEventListeners = (button) => {
  if (button.hasAttribute('button-animation--events-added')) {
    removeEventListenerMulti(button, ['mouseenter', 'focus'], (event) => {
      buttonAnim(event, 'in')
    })

    removeEventListenerMulti(button, ['mouseleave', 'blur'], (event) => {
      buttonAnim(event, 'out')
    })

    button.removeAttribute('button-animation--events-added')
  }
}

const createAnimationElements = (element) => {
  if (!element.hasAttribute('button-animation--created')) {
    const buttonLabel = element.innerText || element.textContent
    const spanBefore = document.createElement('span')
    const spanAfter = document.createElement('span')

    spanBefore.setAttribute('class', 'before')
    spanBefore.setAttribute('data-label', buttonLabel)
    spanAfter.setAttribute('class', 'after')

    element.appendChild(spanBefore)
    element.appendChild(spanAfter)

    element.setAttribute('button-animation--created', '')
  }
}

const destroyAnimationElements = (element) => {
  if (element.hasAttribute('button-animation--created')) {
    const spanBefore = element.querySelector('.before')
    const spanAfter = element.querySelector('.after')
    element.removeChild(spanBefore)
    element.removeChild(spanAfter)

    element.removeAttribute('button-animation--created')
  }
}

const attachElement = (button) => {
  createAnimationElements(button)
  addEventListeners(button)
}

const detachElement = (button) => {
  removeEventListeners(button)
  destroyAnimationElements(button)
}

const init = () => {
  const buttons = Array.from(document.querySelectorAll('.button'))
  buttons.forEach(attachElement)
}

document.addEventListener('DOMContentLoaded', init)

export { attachElement, detachElement }
