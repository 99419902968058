const init = () => {
  const revealItems = Array.from(document.querySelectorAll('.logo-block__logo'))
  if (!revealItems.length > 0) return

  let getDirection = function (e, item) {
    let w = item.offsetWidth
    let h = item.offsetHeight
    let position = getPosition(item)

    // Calculate the x/y value of the pointer entering/exiting, relative to the center of the item.
    let x = (e.pageX - position.x - (w / 2) * (w > h ? (h / w) : 1))
    let y = (e.pageY - position.y - (h / 2) * (h > w ? (w / h) : 1))

    // Calculate the angle the pointer entered/exited and convert to clockwise format (top/right/bottom/left = 0/1/2/3).
    // See https://stackoverflow.com/a/3647634 for a full explanation.
    let d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4

    // console.table([x, y, w, h, e.pageX, e.pageY, item.offsetLeft, item.offsetTop, position.x, position.y])

    return d
  }

  const getPosition = function (el) {
    let xPos = 0
    let yPos = 0

    while (el) {
      xPos += (el.offsetLeft + el.clientLeft)
      yPos += (el.offsetTop + el.clientTop)

      el = el.offsetParent
    }
    return {
      x: xPos,
      y: yPos
    }
  }

  const translateDirection = switchcase({
    0: 'top',
    1: 'right',
    2: 'bottom',
    3: 'left'
  })('top')

  const addClass = function (e, state) {
    let currentItem = e.currentTarget
    let direction = getDirection(e, currentItem)
    let directionString = translateDirection(direction)
    let animationName = 'slide'

    let currentCssClasses = currentItem.className.split(' ')
    let filteredCssClasses = currentCssClasses.filter((cssClass) => (!cssClass.startsWith(animationName))).join(' ')
    currentItem.className = filteredCssClasses
    currentItem.classList.add(`${animationName}--${state}-${directionString}`)
  }

  const addEventListenerMulti = function (element, events, fn) {
    events.forEach((e) => element.addEventListener(e, fn))
  }

  revealItems.forEach((item) => {
    addEventListenerMulti(item, ['mouseenter', 'focus'], (e) => {
      addClass(e, 'in')
    })

    addEventListenerMulti(item, ['mouseleave', 'blur'], (e) => {
      addClass(e, 'out')
    })
  })
}

export const switchcase = cases => defaultCase => key => key in cases ? cases[key] : defaultCase

document.addEventListener('DOMContentLoaded', init)

export { init }
