const { attachElement, detachElement } = require('../../../partials/masthead-scaler')
const vAnimationMasthead = {
  bind (el, binding, vnode) {
    attachElement(el)
  },
  unbind (el, binding, vnode) {
    detachElement(el)
  }
}

module.exports = vAnimationMasthead
