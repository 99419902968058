import { TweenMax, TimelineMax, Power4, Expo, Linear } from 'gsap'
import { mq } from '../helpers/detect-screensize'
import isTouchscreen from '../helpers/detect-touchscreen'
import lazySizes from 'lazysizes'
window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.init = false

const init = () => {
  if (window.siteNavInitialized) return
  window.siteNavInitialized = true

  let menuIsOpen = false

  const siteNav = document.querySelector('.site-nav')
  if (!siteNav) return

  const iconMenu = document.querySelector('.icon-menu')
  const navChaptersItems = document.querySelectorAll('.site-nav__chapters li')
  const navContentItems = Array.from(document.querySelectorAll('.site-nav__main__items'))
  const navContentItemsActive = document.querySelector('.site-nav__main__items.active')
  const navMainItem = Array.from(document.querySelectorAll('.site-nav__main__item'))
  const navMain = document.querySelector('.site-nav__main')
  const navMainContainer = document.querySelector('.site-nav__main__container')
  const navMenu = document.querySelector('.site-nav__menu')
  const navMenuPrimary = document.querySelector('.site-nav__menu__primary')
  const navMenuPrimaryLinks = Array.from(document.querySelectorAll('.site-nav__menu__primary button'))
  const navSlope = document.querySelector('.site-slope--top polygon')
  const navChapters = document.querySelector('.site-nav__chapters')
  const siteLogoPrimary = document.querySelector('.site-logo__primary')
  const menuLineTop = document.querySelector('.icon-menu__line-top')
  const menuLineMiddle = document.querySelector('.icon-menu__line-middle')
  const menuLineBottom = document.querySelector('.icon-menu__line-bottom')

  const tlIcon = new TimelineMax({ paused: true })
    .to(menuLineTop, 0.2, {
      y: 7,
      ease: Power4.easeOut
    }, 'mergeLines')
    .to(menuLineBottom, 0.2, {
      y: -7,
      ease: Power4.easeOut
    }, 'mergeLines')
    .set(menuLineBottom, {
      autoAlpha: 0
    })
    .to(menuLineTop, 0.2, {
      transformOrigin: '50% 50%',
      rotation: 45,
      ease: Power4.easeOut
    }, 'crossLines')
    .to(menuLineMiddle, 0.2, {
      transformOrigin: '50% 50%',
      rotation: -45,
      ease: Power4.easeOut
    }, 'crossLines')

  const tlOverlay = new TimelineMax({ paused: true })
    .set(navChaptersItems, {
      clearProps: 'transform'
    })
    .fromTo(navSlope, 0.3, {
      attr: { points: '1920,0 0,0 0,0 1920,0' }
    }, {
      attr: { points: '1920,800 0,300 0,0 1920,0' },
      ease: Power4.easeOut
    })
    .to(navSlope, 0.4, {
      attr: { points: '1920,1080 0,1080 0,0 1920,0' },
      ease: Power4.easeOut
    })
    .set(document.body, {
      className: '+=no-scroll'
    })
    .set(document.body, {
      className: '+=menu-open'
    })
    .set([iconMenu, siteLogoPrimary], {
      className: '+=active'
    })
    .staggerTo([navChapters, navMain, navMenu], 0.4, {
      autoAlpha: 1,
      ease: Linear.easeNone
    }, 0.25)

  const iconDuration = tlIcon.duration()
  const overlayDuration = tlOverlay.duration()

  const openMenu = (instant = false) => {
    menuIsOpen = true
    tlIcon.duration(instant ? 0.01 : iconDuration).play()
    tlOverlay.duration(instant ? 0.01 : overlayDuration).play()
    lazySizes.init()
  }

  const closeMenu = (instant = false) => {
    menuIsOpen = false
    tlIcon.duration(instant ? 0.01 : iconDuration).reverse()
    tlOverlay.duration(instant ? 0.01 : overlayDuration).reverse()
  }

  const toggleMenu = () => {
    if (menuIsOpen) closeMenu()
    else openMenu()
  }

  // make active item visible
  if (!mq.matches) {
    TweenMax.set(navContentItemsActive, { autoAlpha: 1 })
  }

  /**
   * switchChapter animate vertical text and set active state button
   * @method switchChapter
   */

  function switchChapter (e, state) {
    if (e.target.tagName.toLowerCase() === 'button') {
      let i = Array.from(navMenuPrimary.children).indexOf(e.target.parentElement)

      TweenMax.to(navChaptersItems, 0.7, {
        x: (i + '00' + '%'),
        ease: Power4.easeOut
      })

      navMenuPrimaryLinks.forEach((link) => link.classList.remove('active'))
      if (!e.target.classList.contains('active')) e.target.classList.add('active')
    }

    if (state === 'out') {
      const indexActiveContent = Array.from(navMainContainer.children).indexOf(document.querySelector('.site-nav__main__items.active'))
      const indexActiveButton = Array.from(navMenuPrimary.children).indexOf(document.querySelector('.site-nav__menu__primary button.active').parentElement)

      TweenMax.to(navChaptersItems, 0.7, {
        x: (indexActiveContent + '00' + '%'),
        ease: Power4.easeOut
      })

      if (indexActiveContent !== indexActiveButton) {
        navMenuPrimaryLinks.forEach((link) => link.classList.remove('active'))
        navMenuPrimaryLinks[indexActiveContent].classList.add('active')
      }
    }
  }

  /**
   * switchContent show navigation content
   * @method switchContent
   */

  function switchContent (e) {
    if (e.target.tagName.toLowerCase() === 'button') {
      const i = Array.from(navMenuPrimary.children).indexOf(e.target.parentElement)

      navContentItems.forEach((item, index) => {
        if (i === index) {
          TweenMax.fromTo(item, 1, {
            className: '+=active',
            autoAlpha: 0
          }, {
            autoAlpha: 1,
            ease: Linear.easeNone
          })
        } else {
          TweenMax.set(item, {
            className: '-=active',
            autoAlpha: 0
          })
        }
      })
    }
  }

  /**
    * slideContent show navigation content
    * @method slideContent
    */

  function slideContent (e) {
    if (e.target.tagName.toLowerCase() === 'button') {
      const i = Array.from(navMenuPrimary.children).indexOf(e.target.parentElement)

      navContentItems.forEach((item, index) => {
        if (i === index) {
          TweenMax.to(navMenu, 0.5, {
            x: '-100%',
            ease: Expo.easeOut
          })
          TweenMax.fromTo(item, 0.5, {
            className: '+=active', x: '0'
          }, {
            x: '-100%',
            ease: Expo.easeOut
          })
        } else {
          TweenMax.set(item, {
            className: '-=active'
          })
        }
      })
    }
  }

  /**
   * This back button functionality is only for small devices
   * @method backToMenu
   */

  function backToMenu (e) {
    const activeItem = document.querySelector('.site-nav__main__items.active')

    if (e.target.parentElement.classList.contains('site-nav__main__back-menu')) {
      TweenMax.to(navMenu, 0.5, {
        x: '0%',
        ease: Expo.easeOut
      })
      TweenMax.to(activeItem, 0.5, {
        className: '-=active',
        x: '0%',
        ease: Expo.easeOut
      })
    }
  }

  /**
   * Animate items inside scrollable content
   */

  const containerContentAnim = function () {
    const options = {
      root: navMainContainer,
      rootMargin: '50px',
      threshold: 0
    }

    function handleIntersect (entries, observer) {
      entries.forEach(entry => {
        const isAbove = entry.boundingClientRect.top < entry.rootBounds.top

        if (entry.intersectionRatio > 0) {
          if (isAbove) {
            TweenMax.fromTo(entry.target, 0.8, {
              y: -120,
              scale: 0.9
            }, {
              y: 0,
              scale: 1,
              ease: Expo.easeOut
            })
          } else {
            TweenMax.fromTo(entry.target, 0.8, {
              y: 120,
              scale: 0.9
            }, {
              y: 0,
              scale: 1,
              ease: Expo.easeOut
            })
          }
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, options)
    if (!mq.matches && !isTouchscreen) {
      navMainItem.forEach(item => observer.observe(item))
    }
  }

  containerContentAnim()

  /**
   * initContainerScroll removes scrollbar
   * @method initContainerScroll
   */

  const initContainerScroll = function initContainerScroll () {
    // check scrollbar width and place it outside container
    if (document.body.contains(navMainContainer)) {
      navMainContainer.style.right = navMainContainer.clientWidth - navMainContainer.offsetWidth + 'px'
    }
  }
  if (!mq.matches) {
    window.addEventListener('load', initContainerScroll)
  }

  // Events
  const addEventListenerMulti = function (element, events, fn) {
    events.forEach((e) => element.addEventListener(e, fn))
  }

  addEventListenerMulti(iconMenu, ['click', 'touchstart '], (e) => {
    toggleMenu(e)
  })

  if (mq.matches) {
    addEventListenerMulti(navMenuPrimary, ['click', 'touchstart '], (e) => {
      slideContent(e)
    })
    addEventListenerMulti(navMain, ['click', 'touchstart '], (e) => {
      backToMenu(e)
    })
  } else {
    addEventListenerMulti(navMenuPrimary, ['click', 'touchstart '], (e) => {
      switchContent(e)
    })
    addEventListenerMulti(navMenuPrimary, ['mouseover', 'focus'], (e) => {
      switchChapter(e, 'in')
    })
    addEventListenerMulti(navMenuPrimary, ['mouseleave', 'blur'], (e) => {
      switchChapter(e, 'out')
    })
  }

  /* window.addEventListener('closenavigation', () => {
    if (menuIsOpen) closeMenu(true)
  }) */
}

document.addEventListener('DOMContentLoaded', init, { once: true })
