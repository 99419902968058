import { TweenMax, TimelineMax, Linear } from 'gsap'
import { mq } from '../helpers/detect-screensize'

const init = () => {
  const stripes = Array.from(document.querySelectorAll('.stripes'))
  if (!stripes.length > 0) return

  const patternAnim = function () {
    const tl = new TimelineMax()

    tl
      .to(stripes, 20.0, { attr: { y: 600 }, repeat: -1, ease: Linear.easeNone })
  }

  if (!mq.matches) {
    patternAnim()
  }
}

document.addEventListener('DOMContentLoaded', init)

// TODO: need to not do this multiple times in context of barba
