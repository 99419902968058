// Polyfills
import 'core-js/fn/array/from'
import 'core-js/fn/array/find'
import 'core-js/fn/array/for-each'
import 'core-js/fn/object/assign'
import 'core-js/fn/promise'
import 'core-js/es7/array'
import 'core-js/es7/object'

import 'picturefill'
import 'objectFitPolyfill'
import 'intersection-observer'
import 'svgxuse'

// Edge
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
