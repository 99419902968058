import { TweenMax, TimelineMax, Power4 } from 'gsap'
import _ from 'lodash'
import { debounce } from 'lodash/fp'
import { mq } from '../helpers/detect-screensize'

const createRevealAnim = (revealEl, timeline) => {
  const revealBoxElm = revealEl.firstChild.nextSibling
  const revealer = revealEl.querySelector('.revealer')

  timeline
    .set(revealer, { autoAlpha: 1, x: '-100%' })
    .fromTo(revealer, 0.6, { x: '-100%' }, { x: '0%', ease: Power4.easeOut })
    .set(revealBoxElm, { autoAlpha: 1 })
    .to(revealer, 0.4, { x: '100%', ease: Power4.easeOut })
    .set(revealer, { autoAlpha: 0 })
    .set(revealEl, { className: '-=reveal-box' })
}

const createAnimationElements = (element) => {
  const tl = element.tl = new TimelineMax({ delay: 0.2 })

  if (!element.hasAttribute('revealer--created')) {
    const span = document.createElement('span')
    span.setAttribute('class', 'revealer')
    element.appendChild(span)
    element.setAttribute('revealer--created', '')
  }

  createRevealAnim(element, tl)
  return element
}

const destroyAnimationElements = (element) => {
  if (element.hasAttribute('revealer--created')) {
    const span = element.querySelector('.revealer')
    element.removeChild(span)
    element.removeAttribute('revealer--created')
  }
}

const attachElement = (element) => {
  createAnimationElements(element)
}

const detachElement = (element) => {
  delete element.tl
  destroyAnimationElements(element)
  return element
}

const init = () => {
  const revealBoxes = Array.from(document.querySelectorAll('.reveal-box'))
  if (!revealBoxes.length > 0 || mq.matches) return

  revealBoxes.forEach(attachElement)
}

document.addEventListener('DOMContentLoaded', init)
window.addEventListener('resize', _.debounce(init, 200))

export { init, attachElement, detachElement }
