// file: src/init/component.js
// doc: http://vuejs.org/api/#Vue-component

module.exports = function (Vue) {
  // svg
  Vue.component('svg-use', require('../components/svg-use.vue'))
  // object
  Vue.component('tag-group', require('../components/tag-group.vue'))
  Vue.component('select-options', require('../components/select-options.vue'))
  Vue.component('object-pulldown', require('../components/object-pulldown.vue'))
  // components
  Vue.component('modal', require('../components/modal.vue'))
  Vue.component('masthead-senary', require('../components/masthead-senary.vue'))
  Vue.component('masthead-contact', require('../components/masthead-contact.vue'))
  Vue.component('job-item', require('../components/job-item.vue'))
  Vue.component('jobs-overview', require('../components/jobs-overview.vue'))
  Vue.component('case-item', require('../components/case-item.vue'))
  Vue.component('cases-overview', require('../components/cases-overview.vue'))
  Vue.component('whitepaper-item', require('../components/whitepaper-item.vue'))
  Vue.component('whitepapers-overview', require('../components/whitepapers-overview.vue'))
  Vue.component('insight-item', require('../components/insight-item.vue'))
  Vue.component('insights-overview', require('../components/insights-overview.vue'))
  Vue.component('social-sharing', require('../components/social-sharing.vue'))
  Vue.component('contact-item', require('../components/contact-item.vue'))
  Vue.component('contacts-overview', require('../components/contacts-overview.vue'))
  // forms
  Vue.component('form-whitepaper', require('../components/form-whitepaper.vue'))
  return Vue
}
