import { TweenMax, Linear } from 'gsap'
import { mq } from '../helpers/detect-screensize'
import Plyr from 'plyr'

const init = () => {
  const videos = Array.from(document.querySelectorAll('.video-block'))

  videos.forEach((video, i) => {
    if (!video.hasAttribute('video--created')) {
      video.setAttribute('video--created', '')

      const iconPath = video.dataset.iconUrl
      const options = {
        controls: ['play-large'],
        iconUrl: iconPath,
        iconPrefix: 'icon'
      }

      Array.from(document.querySelectorAll('.js-player')).map(player => new Plyr(player, options))

      const mastheadVid = document.querySelector('.masthead-quaternary .js-player')

      if (mastheadVid && !mq.matches) {
        const mastheadHgroup = document.querySelector('.masthead-quaternary__hgroup')

        video.addEventListener('play', event => {
          TweenMax.to(mastheadHgroup, 0.5, { autoAlpha: 0, ease: Linear.easeNone }, 0.2)
        })

        video.addEventListener('pause', event => {
          TweenMax.to(mastheadHgroup, 0.5, { autoAlpha: 1, ease: Linear.easeNone }, 0.2)
        })

        video.addEventListener('ended', event => {
          const player = event.detail.plyr
          player.stop()
        })
      }
    }
  })
}

document.addEventListener('DOMContentLoaded', init, { once: true })

export { init }
