import { TweenMax, TimelineMax, Linear } from 'gsap'
import { mq } from '../helpers/detect-screensize'

// Debug
// import GSDevTools2 from '../gsap-bonus/GSDevTools'
const createThresholdRange = (startTrigger, endTrigger) => {
  const thresholdRange = []
  let i = startTrigger

  for (i; i <= endTrigger; i += 0.01) {
    thresholdRange.push(i)
  }
  return thresholdRange
}

const createMastheadAnim = (mastheadEl, timeline) => {
  const startTrigger = 0
  const endTrigger = 1.0
  const range = 1 / (endTrigger - startTrigger)
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: createThresholdRange(startTrigger, endTrigger)
  }

  const handleIntersect = (entries, observer) => {
    entries.forEach(entry => {
      const isAbove = entry.boundingClientRect.top < entry.rootBounds.top

      if (entry.intersectionRatio > 0) {
        if (isAbove) {
          const prog = ((entry.intersectionRatio) - startTrigger) * range
          const value = prog < 0 ? 0 : prog > 1 ? 1 : prog

          timeline.progress(-value)
        }
      }
    })
  }

  mastheadEl.observer = new IntersectionObserver(handleIntersect, options)

  if (!mq.matches) {
    mastheadEl.observer.observe(mastheadEl)
  }
}

const attachElement = element => {
  const tl = element.tl = new TimelineMax({ paused: true })
  const mastheadMedia = element.querySelector('img, .video-block')
  const SVGtext = element.querySelector('.js-fit-text')

  if (SVGtext) {
    tl.fromTo([mastheadMedia, SVGtext], 1.0, { scale: 1 }, { scale: 1.25, ease: Linear.easeNone })
  } else {
    tl.fromTo(mastheadMedia, 1.0, { scale: 1 }, { scale: 1.25, ease: Linear.easeNone })
  }

  createMastheadAnim(element, tl)
  return element
}

const detachElement = element => {
  delete element.tl
  delete element.observer
  return element
}

const init = () => {
  const mastheads = Array.from(document.querySelectorAll('.masthead-scaler'))
  if (!mastheads.length > 0) return

  mastheads.forEach(attachElement)
}

document.addEventListener('DOMContentLoaded', init)

export { init, attachElement, detachElement }
