import { TweenMax, TimelineMax, Power4, Linear } from 'gsap'
import SplitText from '../gsap-bonus/SplitText'
import { mq } from '../helpers/detect-screensize'
import isTouchscreen from '../helpers/detect-touchscreen'

const init = () => {
  const services = document.querySelector('.services-block__services')
  if (!services) return

  const serviceItems = Array.from(document.querySelectorAll('.services-block__service'))
  const serviceVisuals = Array.from(document.querySelectorAll('.services-block__visuals__visual'))
  const serviceVisual = document.querySelector('.services-block__visuals__visual')

  /**
   * Splits headings into seperate html elements for animation purposes
   * @method headingLineSplitter
   */

  function headingLineSplitter () {
    const headingSplit = new SplitText('.services-block__service__subheading', { type: 'lines, words', linesClass: 'line', wordsClass: 'word' })
    const lines = headingSplit.lines
    lines.forEach((item) => {
      const span = document.createElement('span')
      item.appendChild(span)
    })
  }

  /**
   * Get all background visuals and set them as background style elements
   * @method setNewBackgrounds
   */
  function setNewBackgrounds () {
    serviceItems.forEach((item, i) => {
      let bgr = serviceVisuals[i].currentSrc
      item.style.backgroundImage = `url(${bgr})`
    })
  }

  // Call functions
  headingLineSplitter()

  if (!mq.matches && !isTouchscreen) {
    serviceVisual.addEventListener('load', setNewBackgrounds())
  }

  /**
   * Reveal box animation
   * @method
   * @param  {[type]} e     target element
   * @param  {[type]} state checks if the element mouseenter is 'in' or mouseleave 'out'
   */

  const revealBox = function (e, state) {
    let currentItem = e.currentTarget

    const tlRevealText = new TimelineMax({ paused: true })
    const tlRevealTextMobile = new TimelineMax({ paused: true })
    const tlHideTextMobile = new TimelineMax({ paused: true })
    const tlSwitchBg = new TimelineMax({ paused: true })
    const foot = currentItem.querySelector('.services-block__service__foot')
    const allFoot = document.querySelectorAll('.services-block__service__foot')
    const openIcon = currentItem.querySelector('.services-block__service__open-icon')
    const allOpenIcon = document.querySelectorAll('.services-block__service__open-icon')
    const headingRevealElm = currentItem.querySelectorAll('.services-block__service__subheading span')
    const heading = currentItem.querySelectorAll('.services-block__service__subheading')
    const headingText = currentItem.querySelectorAll('.services-block__service__subheading .word')
    const link = currentItem.querySelector('.services-block__service__link')
    const i = Array.from(services.children).indexOf(currentItem)

    tlSwitchBg
      .add('start')
      .to(serviceVisuals, 0.4, { className: '-=active', autoAlpha: 0, ease: Linear.easeNone }, 'start')
      .to(serviceVisuals[i], 0.4, { className: '+=active', autoAlpha: 1, ease: Linear.easeNone }, 'start')

    tlRevealText
      .set([foot, heading], { autoAlpha: 1 })
      .set([headingText, link], { autoAlpha: 0 })
      .set(headingRevealElm, { autoAlpha: 1, x: '-100%' })
      .staggerFromTo(headingRevealElm, 0.4, { x: '-100%' }, { x: '0%', delay: 0.3, ease: Power4.easeOut }, 0.1)
      .set(headingText, { autoAlpha: 1 })
      .staggerTo(headingRevealElm, 0.2, { x: '100%', ease: Power4.easeOut }, 0.1)
      .set(headingRevealElm, { autoAlpha: 0 })
      .to(link, 0.4, { autoAlpha: 1 }, '-=0.1')

    tlRevealTextMobile
      .add('start')
      .to(allOpenIcon, 0.05, { rotation: 0, className: '-=open', ease: Power4.easeOut }, 'start')
      .to(allFoot, 0.2, { height: '0px', ease: Power4.easeOut }, 'start')
      .to(foot, 0.4, { height: '255px', ease: Power4.easeOut }, 'start')
      .to(openIcon, 0.05, { rotation: -135, className: '+=open', ease: Power4.easeOut }, 'start')

    tlHideTextMobile
      .add('start')
      .to(allFoot, 0.4, { height: '0px', ease: Power4.easeOut }, 'start')
      .to(allOpenIcon, 0.05, { rotation: 0, className: '-=open', ease: Power4.easeOut }, 'start')

    switch (state) {
    case 'in':
      tlRevealText.play()
      tlSwitchBg.play()
      break
    case 'out':
      TweenMax.to(foot, 0.4, { autoAlpha: 0, ease: Power4.easeOut })
      break
    case 'open':
      if (!openIcon.classList.contains('open')) {
        tlRevealTextMobile.play()
        tlSwitchBg.play()
      } else if (e.target.classList.contains('open')) {
        tlHideTextMobile.play()
      }
      break
    }
  }

  const addEventListenerMulti = function (element, events, fn) {
    events.forEach((e) => element.addEventListener(e, fn))
  }

  serviceItems.forEach((item) => {
    addEventListenerMulti(item, ['mouseenter', 'focus'], (e) => {
      if (!mq.matches && !isTouchscreen) {
        revealBox(e, 'in')
      }
    })

    addEventListenerMulti(item, ['mouseleave', 'blur'], (e) => {
      if (!mq.matches && !isTouchscreen) {
        revealBox(e, 'out')
      }
    })

    addEventListenerMulti(item, ['click'], (e) => {
      if (mq.matches || isTouchscreen) {
        revealBox(e, 'open')
      }
    })
  })
}

window.addEventListener('load', init)

export { init }
