'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'MastheadContact'
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"masthead-contact masthead-scaler\">\n  <div class=\"masthead-contact__container reveal-box\" v-animation-reveal=\"\">\n    <div class=\"masthead-contact__inner\">\n\n      <div class=\"masthead-contact__nav\">\n        <h6 class=\"masthead-contact__nav__label\">Choose</h6>\n        <slot name=\"list\"></slot>\n      </div>\n\n      <div class=\"masthead-contact__text\">\n        <svg viewBox=\"0 0 1600 720\" class=\"js-fit-text\" data-indent=\"100\" v-svg-fit-text=\"\">\n          <text x=\"0\" y=\"830\">Offices</text>\n        </svg>\n      </div>\n      <div class=\"masthead-contact__visual\">\n        <slot name=\"picture\"></slot>\n      </div>\n    </div>\n  </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-14197d6e", module.exports)
  } else {
    hotAPI.update("_v-14197d6e", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}