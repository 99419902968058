import _ from 'lodash'
import { debounce } from 'lodash/fp'

const init = () => {
  const teaserHgroup = document.querySelectorAll('.teaser-block__teaser__hgroup')
  if (!teaserHgroup) return

  function setHeaderWidth () {
    Array.from(teaserHgroup).forEach((item, i) => {
      item.removeAttribute('style')
      item.style.width = item.clientWidth + 'px'
    })
  }

  setHeaderWidth()

  window.addEventListener('resize', _.debounce(setHeaderWidth, 200))
}

document.addEventListener('DOMContentLoaded', init)

export { init }
