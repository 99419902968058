import { TweenMax, TimelineMax, Power4, Linear } from 'gsap'
import _ from 'lodash'
import { throttle } from 'lodash/fp'
import { mq } from '../helpers/detect-screensize'
import isTouchscreen from '../helpers/detect-touchscreen'

const init = () => {
  const body = document.body
  const siteSectionTitle = document.querySelector('.site-section-title')
  const sectionTitle = document.querySelector('.section-title')

  if (body.contains(siteSectionTitle) === false || body.contains(sectionTitle) === false) {
    return
  } else if (mq.matches && isTouchscreen) {
    return
  }

  siteSectionTitle.classList.add('active')

  const title = document.querySelector('.site-section-title__text')
  title.setAttribute('title--created', '')

  const sections = Array.from(document.querySelectorAll('.section-title'))
  const sectionTitleSvg = document.querySelector('.site-section-title')
  const directionClass = 'flipped'
  let windowOldPos = 0

  const spanAfter = document.createElement('span')
  spanAfter.setAttribute('class', 'after')
  title.appendChild(spanAfter)

  /**
   * When scrolled change section title
   * @method changeTitle
   * @type {[type]}
   */
  function changeTitle () {
    let windowPos = window.pageYOffset + (window.innerHeight / 2)
    let activeChildIndex = -1

    sections.forEach(section => {
      if (windowPos > section.offsetTop) {
        activeChildIndex++
      }
    })

    let activeChild = sections[activeChildIndex]
    const afterElm = title.querySelector('.after')
    const textElm = title.querySelector('.site-section-title__text-inner')

    if (activeChild.dataset.label !== textElm.dataset.label) {
      const tl = new TimelineMax()
      tl
        .set(afterElm, { x: '-100%' })
        .fromTo(textElm, 0.2, { autoAlpha: 1 }, { autoAlpha: 0, ease: Linear.easeNone })
        .add(() => { textElm.dataset.label = activeChild.dataset.label })
        .to(afterElm, 0.4, { x: '0%', ease: Power4.easeOut })
        .set(textElm, { autoAlpha: 1 })
        .to(afterElm, 0.2, { x: '100%', ease: Power4.easeInOut })
    }
  }

  /**
   * When scrolled change arrow direction
   * @method changeArrowDirection
   * @type {[type]}
   */
  function changeArrowDirection () {
    if (window.scrollY === 0) {
      sectionTitleSvg.classList.add(directionClass)
    } else if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      sectionTitleSvg.classList.remove(directionClass)
    } else if (window.scrollY >= windowOldPos) {
      sectionTitleSvg.classList.add(directionClass)
    } else {
      sectionTitleSvg.classList.remove(directionClass)
    }
    windowOldPos = window.scrollY
  }

  window.addEventListener('scroll', _.throttle(changeArrowDirection, 100))
  window.addEventListener('scroll', _.throttle(changeTitle, 100))

  changeArrowDirection()
  changeTitle()

  if (title && !title.hasAttribute('title--created')) {

  }
}

document.addEventListener('DOMContentLoaded', init)

export { init }
