const isTouchscreen = () => {
  var ts = false

  // iOS & android
  if ('ontouchstart' in window) {
    ts = true

  // Controversal way to check touch support
  } else if ('ontouchstart' in document.documentElement) {
    ts = true
  }

  return ts
}

export default isTouchscreen()
