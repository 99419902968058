import { TweenMax, TimelineMax, Power3, Power4, Linear, SteppedEase, Elastic, Back, Expo } from 'gsap'
import DrawSVGPlugin from '../gsap-bonus/DrawSVGPlugin'
import SplitText from '../gsap-bonus/SplitText'
import Cookies from 'js-cookie'
import { mq } from '../helpers/detect-screensize'
import { prefetchAssets } from '../helpers/prefetch-assets'

// GSAP Advanced playback
// import GSDevTools from '../gsap-bonus/GSDevTools'

/**
 * Site intro/banner Valtech
 *
 * @module component/banner-slider
 * @method init
 */

const init = () => {
  const body = document.body
  const bannerSlider = document.querySelector('.banner-slider')
  if (!bannerSlider) return

  const siteIntro = document.querySelector('.site-intro')
  const logoLodash = document.querySelector('.site-intro__logo__lodash')
  const logoText = document.querySelector('.site-intro__logo__text')
  const logoBorder = document.querySelector('.site-intro__logo__border')
  const logoColor = document.querySelector('.site-intro__logo__color')
  const logoBox = document.querySelector('.site-intro__logo__box')
  const logoClip = document.querySelector('.site-intro__logo__clip')

  const boxTitle = document.querySelector('.banner-slider__slide-content__box__title')
  const boxBorder = document.querySelector('.banner-slider__slide-content__box__border')

  const siteLogo = document.querySelector('.site-logo__primary')

  const siteNavIcons = document.querySelector('.site-nav__icons')
  const siteChapter = document.querySelector('.site-chapter')

  const bannerContainer = document.querySelector('.banner-slider__slide-items')
  const bannerItem = document.querySelectorAll('.banner-slider__slide-items__item')

  // small screen elements
  const smBannerSubtitles = document.querySelectorAll('.banner__content__subtitle')
  const smBannerBox = document.querySelector('.banner__box')
  const smBannerIntro = document.querySelector('.banner__intro-block')
  const smBannerMo = document.querySelector('.banner__gimme-mo__label')
  const smBannerPicture = document.querySelector('.banner__container img')

  const bannerSlotMachine = document.querySelectorAll('.banner-slider__slide-content__slot-machine')
  const bannerSlotMachinePrimaryList = document.querySelector('.banner-slider__slide-content__slot-machine--primary ul')
  const bannerSlotMachinePrimaryListItems = document.querySelectorAll('.banner-slider__slide-content__slot-machine--primary li')
  const bannerSlotMachineSecondaryList = document.querySelector('.banner-slider__slide-content__slot-machine--secondary ul')
  const bannerSlotMachineSecondaryListItems = document.querySelectorAll('.banner-slider__slide-content__slot-machine--secondary li')
  const bannerSlotMachineList = document.querySelectorAll('.banner-slider__slide-content__list')
  const bannerSlotMachineItem = document.querySelectorAll('.banner-slider__slide-content__list li')
  let slotTimes = 0
  const slotList = document.querySelector('.banner-slider__slide-content__list')
  const slotListHeight = slotList.clientHeight
  const slotListItemHeight = slotListHeight / slotList.childElementCount
  const bannerIntro = document.querySelectorAll('.banner-slider__slide-content__intro-block')

  const bannerGimmeMo = document.querySelector('.banner-slider__gimme-mo')

  const bannerIndicator = document.querySelector('.banner-slider__indicator')
  let indicatorTimes = 0

  const tlMaster = new TimelineMax()
  const tlLodashLoader = new TimelineMax({ paused: true }).add(getBlinkLodash())
  const tlBoxLoader = new TimelineMax({ paused: true }).add(getLoopDrawBox())

  // Check if 'no-scroll' class exists and navigation is not active
  // If true remove 'no-scroll' class from body elm
  const checkScrollClass = function () {
    if (body.classList.contains('no-scroll') && !document.querySelector('.icon-menu.active')) {
      body.classList.remove('no-scroll')
    }
  }

  /**
   * ClearStage set all element to there default state
   *
   * @method clearStage
   * @return {Object}
   */

  function clearStage () {
    const tl = new TimelineMax()

    tl
      .set(siteIntro, { css: { backgroundColor: 'white' } })
      .set([logoBorder, boxBorder], { drawSVG: '0%' })
      .set([logoBox, siteLogo, siteNavIcons, siteChapter, bannerGimmeMo, bannerIndicator], { autoAlpha: 0 })
      .set(body, { className: '+=no-scroll' })
      .kill()

    return tl
  }

  /**
   * Animate lodash line into the page + text
   *
   * @method getLine
   * @return {Object}
   */

  function getLine () {
    const tl = new TimelineMax({id: 'getLine'})

    tl
      .fromTo(logoLodash, 0.5, { autoAlpha: 0, scaleX: 0 }, { autoAlpha: 1, scaleX: 1, ease: Power4.easeInOut })
      .fromTo(logoLodash, 0.3, { scaleX: 1 }, { scaleX: 0, transformOrigin: 'right 0', ease: Power4.easeInOut })
      .set(logoLodash, { attr: { width: '26.7' }, scaleX: 1, transformOrigin: 'right 0', x: '1103.3', onComplete: () => tlLodashLoader.play() })
      .fromTo(logoText, 0.6, { x: 308, autoAlpha: 1 }, { x: 0, ease: SteppedEase.config(7) }, '+=1.2')
      .kill()

    return tl
  }

  /**
   * Blink logo dash when assets are loading
   *
   * @method getBlinkLodash
   * @return {Object}
   */

  function getBlinkLodash () {
    const tl = new TimelineMax({ id: 'getBlinkLodash' })

    tl.fromTo(logoLodash, 0.2, { autoAlpha: 0 }, { autoAlpha: 1, repeat: -1, repeatDelay: 0.1, yoyo: true, ease: Linear.easeNone })

    return tl
  }

  /**
   * Draw box and reveal text
   *
   * @method getLoopDrawBox
   * @return {[Object]}
   */

  function getLoopDrawBox () {
    const tl = new TimelineMax({
      id: 'getLoopDrawBox',
      repeat: -1,
      repeatDelay: 0.2
    })

    tl
      .to(boxBorder, 1.2, { drawSVG: '100% 100%', ease: Power4.easeInOut })
      .fromTo(boxBorder, 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power4.easeInOut })

    return tl
  }

  /**
   * Expand the black box around the logo
   *
   * @method getExpandBox
   * @return {Object}
   */

  function getExpandBox () {
    const tl = new TimelineMax({ id: 'getExpandBox' })

    tl
      .fromTo(logoBorder, 0.8, { drawSVG: '0%' }, { drawSVG: '100%', onStart: () => { tlLodashLoader.pause(-1) } })
      .set(logoBorder, { fill: 'currentColor' })
      .set(logoColor, { color: '#fff' })
      .fromTo(logoBox, 0.4, { scale: 0, transformOrigin: '50% 50%' }, { scale: 1, autoAlpha: 1, ease: Expo.easeOut })
      .to(logoBox, 0.4, { attr: { points: '1920.5,0.5 764.5,474.5 764.5,605.5 1155.5,605.5' }, delay: 0.2, ease: Power4.easeOut })
      .to(logoBox, 0.4, { attr: { points: '1920.5,0.5 764.5,474.5 764.5,605.5 1920.5,1080.5' }, ease: Power4.easeOut }, '-=0.2')
      .to(logoBox, 0.4, { attr: { points: '1920.5,0.5 0.5,0.5 764.5,605.5 1920.5,1080.5' }, ease: Power4.easeOut }, '-=0.2')
      .to(logoBox, 0.4, { attr: { points: '1920.5,0.5 0.5,0.5 0.5,790.6 0.5,1080.5 1920.5,1080.5' }, ease: Power4.easeOut }, '-=0.2')
      .kill()

    return tl
  }

  /**
   * Hide logo text
   *
   * @method getHideLogo
   * @return {[Object]}
   */

  function getHideLogo () {
    const tl = new TimelineMax({ id: 'getHideLogo' })

    tl
      .add('hide-text')
      .to(logoLodash, 0.3, { x: '760', ease: Linear.easeNone })
      .to(logoLodash, 0.3, { attr: { width: '0' }, ease: Linear.easeNone }, '-=0.2')
      .to(logoClip, 0.21, { attr: { width: '0' }, ease: Linear.easeNone }, 'hide-text')
      .set(siteIntro, { autoAlpha: 0 })
      .kill()

    return tl
  }

  /**
   * Draw box and reveal text
   *
   * @method getDrawBox
   * @return {[Object]}
   */

  function getDrawBox (cookieSet) {
    const tl = new TimelineMax({ id: 'getDrawBox' })
    const boxTitleChars = new SplitText(boxTitle, { type: 'chars' })

    tl
      .set(boxTitle, { autoAlpha: 1 })
      .fromTo(boxBorder, 0.8, { drawSVG: '0%' }, { drawSVG: '100%', onComplete: () => { if (cookieSet === true) { tlBoxLoader.play() } } })
      .staggerFromTo(boxTitleChars.chars, 0.4, { autoAlpha: 0, xPercent: -300, 'filter': 'blur(40px)' }, { autoAlpha: 1, xPercent: 0, 'filter': 'blur(0px)', ease: Power3.easeInOut }, -0.1)
      .kill()

    return tl
  }

  /**
   * Reveal site elements
   * This is for small screens only
   *
   * @method getRevealSiteElements
   * @return {[Object]}
   */

  function getRevealSiteElements () {
    const tl = new TimelineMax({ id: 'getRevealSiteElements' })

    tl
      .add(checkScrollClass)
      .fromTo(siteLogo, 1.0, { x: -10, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Power4.easeOut }, 'start')
      .to(siteNavIcons, 1.0, { autoAlpha: 1, ease: Linear.easeNone }, 'start+=0.3')
      .fromTo(smBannerPicture, 1.0, { scale: 0.98, 'filter': 'brightness(3)' }, { scale: 1.0, 'filter': 'brightness(1)', ease: Linear.easeNone }, 'start')
      .fromTo(smBannerSubtitles, 0.5, { x: -20, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Power4.easeOut }, '-=0.2')
      .fromTo(smBannerBox, 0.7, { x: -20, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Back.easeOut.config(1.0) }, '-=0.4')
      .fromTo(smBannerIntro, 0.6, { autoAlpha: 0 }, { autoAlpha: 1, ease: Linear.easeNone }, '-=0.2')
      .fromTo(smBannerMo, 0.4, { y: 20, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: Power4.easeOut }, '-=0.4')

    return tl
  }

  /**
   * Reveal banner
   *
   * @method getRevealBanner
   * @return {[Object]}
   */

  function getRevealBanner () {
    const tl = new TimelineMax({ id: 'getRevealBanner' })
    const activeItem = document.querySelector('.banner-slider__slide-items__item.active')

    tl
      .fromTo(siteLogo, 1.0, { x: -10, autoAlpha: 0 }, { x: 0, autoAlpha: 1, ease: Power4.easeOut }, 'start')
      .to(siteNavIcons, 1.0, { autoAlpha: 1, ease: Linear.easeNone }, 'start+=0.3')
      .to([siteChapter, bannerIndicator], 0.8, {
        autoAlpha: 1,
        onComplete: () => playItem(activeItem)
      }, 'start')
      .to([bannerContainer, bannerSlotMachine], 0.8, { autoAlpha: 1 }, '-=0.4')
      .fromTo(bannerGimmeMo, 0.2, { y: 20, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: Power4.easeOut }, '-=0.4')
      .add(checkScrollClass)
      .kill()

    return tl
  }

  /**
   * Spin slotmachine
   *
   * @method getSlotMachine
   * @return {[Object]}
   */

  function getSlotMachine () {
    const tl = new TimelineMax({ id: 'getSlotMachine' })

    tl
      .set(bannerSlotMachineItem, {'filter': 'blur(2px)'})
      .set(bannerSlotMachine, { css: { height: slotListItemHeight, lineHeight: slotListItemHeight + 'px' } })
      .fromTo(bannerSlotMachinePrimaryList, 0.2, { y: 0 }, { y: -slotListHeight, repeat: 11, ease: Linear.easeNone }, 'slotmachine')
      .fromTo(bannerSlotMachineSecondaryList, 0.2, { y: 0 }, { y: -slotListHeight, repeat: 11, ease: Linear.easeNone, onComplete: () => replaceText() }, 'slotmachine+=0.1')
      .set(bannerSlotMachineList, { clearProps: 'transform' })
      .fromTo(bannerSlotMachinePrimaryList, 0.8, { y: slotListItemHeight }, { y: 0, ease: Elastic.easeOut.config(1, 0.75) }, 'lastRound')
      .fromTo(bannerSlotMachineSecondaryList, 0.8, { y: slotListItemHeight }, { y: 0, ease: Elastic.easeOut.config(1, 0.75) }, 'lastRound')
      .to(bannerSlotMachineItem, 1.2, { textShadow: 0, 'filter': 'blur(0)' }, 'lastRound')

    return tl
  }

  /**
   * Show Intro text
   *
   * @method getIntroText
   * @return {[Object]}
   */

  function getIntroText () {
    const tl = new TimelineMax({ id: 'getIntroText' })
    const activeIntro = document.querySelectorAll('.banner-slider__slide-content__intro-block')

    tl
      .to(activeIntro[0], 0.5, { autoAlpha: 1, ease: Linear.easeNone })
      .kill()

    return tl
  }

  /**
   * Play video
   * @method playItem
   * @param  {[type]} item expect a video element
   */

  function playItem (item) {
    const video = item.querySelector('video')
    if (video) {
      video.play()
    }
  }

  /**
   * Stop video
   * @method stopItem
   * @param  {[type]} item expect a video element
   */

  function stopItem (item) {
    const video = item.querySelector('video')
    if (video) {
      video.pause()
    }
  }

  /**
   * On every turn, the text is replaced from the next item in the array.
   * These texts come from a dataset attr in the HTML
   * @method replaceText
   */

  function replaceText () {
    const primaryListItemsTexts = bannerSlotMachinePrimaryList.dataset.primaryList.split(',')
    const secondaryListItemsTexts = bannerSlotMachineSecondaryList.dataset.secondaryList.split(',')
    const totalListItems = primaryListItemsTexts.length - 1

    if (slotTimes > totalListItems) {
      slotTimes = 0
    } else {
      bannerSlotMachinePrimaryListItems[0].innerText = primaryListItemsTexts[slotTimes]
      bannerSlotMachineSecondaryListItems[0].innerText = secondaryListItemsTexts[slotTimes]

      if (slotTimes === totalListItems) {
        slotTimes = 0
      } else {
        slotTimes += 1
      }
    }
  }

  /**
   * This is the actual slider fly-in/out animation
   * Item can be a video or image
   * @method getNextItem
   */

  function getNextItem () {
    const tl = new TimelineMax({
      id: 'getNextItem',
      repeat: -1
    })

    const items = Array.from(bannerItem)

    items.forEach((item, i) => {
      let nextItem = items[i + 1]
      if (!nextItem) nextItem = items[0]

      const itemsTl = new TimelineMax()

      itemsTl
        .to(item, 0.5, { scale: 0.8, ease: Power4.easeInOut })
        .call(stopItem, [item])
        .to(item, 0.5, { x: '-100%', autoAlpha: 0, ease: Power4.easeOut })
        .set(item, { x: '100%' })
        .to(nextItem, 0.5, { x: '0%', autoAlpha: 1, ease: Power4.easeOut })
        .call(playItem, [nextItem])
        .to(nextItem, 0.5, { scale: 1, ease: Power4.easeInOut })
        .delay(5)

      tl
        .add(itemsTl)
        .add(getSlotMachine, '-=1.0')
    })

    return tl
  }

  /**
   * Every video/image item has it's own intro text
   * this fades in/out the corresponding intro
   * @method getNextIntro
   */

  function getNextIntro () {
    const tl = new TimelineMax({
      id: 'getNextIntro',
      repeat: -1
    })

    const intros = Array.from(bannerIntro)

    intros.forEach((intro, i) => {
      let nextIntro = intros[i + 1]
      if (!nextIntro) nextIntro = intros[0]

      const introTl = new TimelineMax()

      introTl
        .to(intro, 0.5, { autoAlpha: 0, ease: Linear.easeNone, onComplete: () => loopIndicator() })
        .to(nextIntro, 0.5, { autoAlpha: 1, ease: Linear.easeNone })
        .delay(6)

      tl.add(introTl)
    })

    return tl
  }

  /**
   * Create indicator elements
   * @method createIndicators
   */
  function createIndicators () {
    const banners = Array.from(bannerItem)

    banners.forEach((banner, i) => {
      const span = document.createElement('span')
      span.setAttribute('class', 'banner-slider__indicator__line')
      bannerIndicator.appendChild(span)
    })
  }

  /**
   * Loop through all indicators
   * @method loopIndicator
   */
  function loopIndicator () {
    const bannerIndicatorLines = Array.from(document.querySelectorAll('.banner-slider__indicator__line'))
    const totalIndicators = bannerIndicatorLines.length
    const className = 'active'

    bannerIndicatorLines.forEach((indicator, i) => {
      if (indicator.classList.contains(className)) {
        indicator.classList.remove(className)
      }
    })

    if (indicatorTimes === totalIndicators) {
      indicatorTimes = 0
      bannerIndicatorLines[indicatorTimes].classList.add(className)
    } else {
      bannerIndicatorLines[indicatorTimes].classList.add(className)
      indicatorTimes += 1
    }
  }

  // Call create slider indicators, call loop indicator
  createIndicators()
  loopIndicator()

  const assets = Array.from(document.querySelectorAll('[data-prefetch]'))

  // Master Timeline
  if (Cookies.get('intro') === 'true' && !mq.matches) {
    tlMaster
      .add(getDrawBox(true))
      .addPause('+=0', () => {
        prefetchAssets(assets)
          .then(() => {
            tlBoxLoader.kill()
            tlMaster.play()
          })
          .catch(() => {})
      })
      .add(getRevealBanner())
      .add(getSlotMachine(), '-=1.0')
      .add(getIntroText(), '-=0.5')
      .add(([getNextItem, getNextIntro]))
  } else {
    if (!mq.matches) {
      tlMaster
        .add(clearStage())
        .add(getLine())
        .addPause('+=0', () => {
          prefetchAssets(assets)
            .then(() => {
              tlMaster.play()
            })
            .catch(() => {})
        })
        .add(getExpandBox())
        .add(getHideLogo())
        .add(getDrawBox(false))
        .add(getRevealBanner())
        .add(getSlotMachine(), '-=1.0')
        .add(getIntroText(), '-=0.5')
        .add(([getNextItem, getNextIntro]))

      // Set Intro cookie
      Cookies.set('intro', 'true', { expires: 30 })
    } else {
      tlMaster
        .add(clearStage())
        .add(getLine())
        .add(getExpandBox())
        .add(getHideLogo())
        .add(getRevealSiteElements())
    }
  }

  /* window.addEventListener('killanimations', () => {
    tlMaster.getChildren().forEach(child => child.kill())
    tlMaster.kill()
  }, { once: true })

  window.addEventListener('killallpromises', () => {
    // TODO: reject pending prefetch promises and kill the XMLHttpRequests
  }, { once: true }) */

  // GSAP Advanced playback settings
  // GSDevTools.create({
  //   animation: 'getRevealSiteElements'
  // })
}

document.addEventListener('DOMContentLoaded', init)
