import MicroModal from 'micromodal'

const init = () => {
  if (window.microModalInitialized) return
  window.microModalInitialized = true

  MicroModal.init({
    disableFocus: true,
    disableScroll: true,
    awaitCloseAnimation: true
  })
}

document.addEventListener('DOMContentLoaded', init, { once: true })

export { init }
