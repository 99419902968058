const init = () => {
  const duotone = document.querySelector('.duotone')

  // HEX to RGB conversion
  function hexToRgb (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }

  // Plot colors on the color matrix
  function convertToDueTone (color1, color2) {
    const matrix = document.querySelector('feColorMatrix')
    let value = []

    value = value.concat([color1[0] / 256 - color2[0] / 256, 0, 0, 0, color2[0] / 256])
    value = value.concat([color1[1] / 256 - color2[1] / 256, 0, 0, 0, color2[1] / 256])
    value = value.concat([color1[2] / 256 - color2[2] / 256, 0, 0, 0, color2[2] / 256])
    value = value.concat([0, 0, 0, 1, 0])

    matrix.setAttribute('values', value.join(' '))
  }

  // Highlights / Shadows
  if (duotone && !duotone.hasAttribute('duotone--created')) {
    duotone.setAttribute('duotone--created', '')

    const highlights = duotone.dataset.highlights
    const shadows = duotone.dataset.shadows
    convertToDueTone([hexToRgb(highlights).r, hexToRgb(highlights).g, hexToRgb(highlights).b], [hexToRgb(shadows).r, hexToRgb(shadows).g, hexToRgb(shadows).b])
  }
}

document.addEventListener('DOMContentLoaded', init)
