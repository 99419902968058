import { TweenMax, TimelineMax, Linear, Power4, Back } from 'gsap'
import { mq } from '../helpers/detect-screensize'
import isTouchscreen from '../helpers/detect-touchscreen'

const init = () => {
  const slideNav = document.querySelector('.slide-nav')
  if (!slideNav) return

  const slideToggler = document.querySelector('.slide-nav__header')
  const slideContent = document.querySelector('.slide-nav__content')
  const slideHeight = slideContent.firstChild.nextSibling.clientHeight
  const slideIcon = document.querySelector('.slide-nav__icon svg')

  const tlMaster = new TimelineMax({ paused: true, reversed: true })

  function slideToggle () {
    const tl = new TimelineMax()

    tl
      .to(slideIcon, 0.4, { rotation: -225, transformOrigin: '50% 50%', ease: Back.easeOut.config(1) }, 'start')
      .fromTo(slideContent, 0.5, { height: 0 }, { height: slideHeight, ease: Power4.easeInOut }, 'start')
      .to(slideContent, 0.4, { autoAlpha: 1, ease: Linear.easeNone }, '-=0.2')

    return tl
  }

  const toggleSlideNav = (e) => {
    tlMaster.reversed() ? tlMaster.play() : tlMaster.reverse()
  }

  if (mq.matches || isTouchscreen) {
    tlMaster.add(slideToggle())
  }

  // Events
  const addEventListenerMulti = function (element, events, fn) {
    events.forEach((e) => element.addEventListener(e, fn))
  }

  addEventListenerMulti(slideToggler, ['click', 'touchstart '], (e) => {
    toggleSlideNav(e)
  })
}

document.addEventListener('DOMContentLoaded', init)

export { init }
