const { attachElement, detachElement } = require('../../../partials/buttons')
const vAnimationButton = {
  bind (el, binding, vnode) {
    attachElement(el)
  },
  unbind (el, binding, vnode) {
    detachElement(el)
  }
}

module.exports = vAnimationButton
