'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'MastheadSenary',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n  <section class=\"masthead-senary\">\n    <div class=\"masthead-senary__container\">\n    <div class=\"masthead-senary__inner\">\n      <div class=\"masthead-senary__text masthead-senary__text--light\">\n        <h1 class=\"masthead-senary__text__title\" v-text=\"item.title\">Start\nengineering\nyour own path</h1>\n        <div class=\"masthead-senary__text__lead\">\n          <p v-text=\"item.excerpt\">What started as a design challenge evolved into a full-on partnership for digital transformation.</p>\n        </div>\n        <div class=\"masthead-senary__text__cta\">\n          <a class=\"button button--large button--primary-hollow\" v-if=\"item.url\" v-animation-button=\"\" :href=\"item.url\">\n            Find out more\n          </a>\n        </div>\n      </div>\n      <div class=\"masthead-senary__media\">\n\n        <template v-if=\"item.video\">\n        <!-- Vimeo Video -->\n        <div class=\"video-block\" data-icon-url=\"../../img/img.svg\">\n          <div class=\"video-block__container\">\n            <div class=\"js-player\" data-plyr-provider=\"vimeo\" data-plyr-embed-id=\"76979871\"></div>\n          </div>\n        </div>\n        </template>\n\n        <template v-else-if=\"item.originalImage || item.image\">\n        <picture>\n          <source v-for=\"(source, index) in item.sources\" :key=\"index\" :media=\"source.media\" :srcset=\"source.srcset\">\n\n          <img :src=\"item.originalImage || item.image\" :srcset=\"item.srcset\" :alt=\"item.title\">\n        </picture>\n        </template>\n      </div>\n    </div>\n  </div>\n  </section>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-9a45e71a", module.exports)
  } else {
    hotAPI.update("_v-9a45e71a", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}