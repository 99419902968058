// Polyfills
import './polyfills'

// Vendors
import 'flickity'

// Components
import './components/banner-slider'
import './components/site-logo'
import './components/site-nav'
import './components/services'
import './components/teasers'
import './components/icon-block'
import './components/logo-block'
import './components/profile'
import './components/loop'

// Partials
import './partials/buttons'
import './partials/tiled-stripes'
import './partials/video'
import './partials/ticker'
import './partials/section-title'
import './partials/duotone'
import './partials/modal'
import './partials/file-input'
import './partials/fit-text'
import './partials/slide-nav'
import './partials/slide-fade-in'
import './partials/slide-zoom-in'
import './partials/masthead-scaler'
import './partials/glitch-effect'
import './partials/particles-bg'
import './partials/form-whitepaper'
import './partials/reveal-box'

// Helpers
import './helpers/detect-browser'

// Transitions
// import './transitions/page'

// Apps
import './vue'
