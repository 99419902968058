const attachElement = el => {
  const indent = el.dataset && el.dataset.indent || 0
  const viewBox = el.getAttribute('viewBox').split(' ')
  const size = viewBox.slice(2)
  const viewBoxWidth = size[0]
  const viewBoxHeight = size[1]

  const width = viewBoxWidth - indent
  const height = viewBoxHeight - indent

  const textNode = el.getElementsByTagName('text')[0]
  const textBox = textNode.getBBox()

  const posX = textNode.getAttribute('x')
  const posY = textNode.getAttribute('y')

  const widthTransform = width / textBox.width
  const heightTransform = height / textBox.height
  const value = widthTransform < heightTransform ? widthTransform : heightTransform

  const newXvalue = posX / value
  const newYvalue = posY / value

  textNode.setAttribute('transform', 'scale(' + value + ')')

  textNode.setAttribute('x', newXvalue)
  textNode.setAttribute('y', newYvalue)
}

const init = () => {
  const fitTexts = Array.from(document.querySelectorAll('.js-fit-text'))
  fitTexts.forEach(fitText => {
    if (!fitText.hasAttribute('text--created')) {
      fitText.setAttribute('text--created', '')

      attachElement(fitText)
    }
  })
}

document.addEventListener('DOMContentLoaded', init)

export { attachElement }
